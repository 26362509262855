<template>
  <main class="navbar-offset-tablet salon-flow">
    <PageNavigator
      :page-title="$t('salon-working-hours.breadcrumbs-list-label')"
      :has-back="true"
      :button-back-link="'/salon'"
    >
      <template v-slot:action>
        <div class>
          <button :disabled="submitProcess" class="btn btn-primary px-4 py-1" @click="submitForm()">
            <span class="font-weight-medium poppins-font">
              {{ $t('common.submit-btn') }}
            </span>
          </button>
        </div>
      </template>
    </PageNavigator>

    <div v-if="dataLoaded" class="px-1-5 pt-7-5 full-page-height">
      <div class="h-100">
        <div class="col-lg-6 col-md-8 px-0">
          <div
            v-for="(day, key) in workDays"
            :key="`day_${key}`"
            class="border-bottom"
            :class="{ 'mt-1-5': key !== 0 }"
          >
            <div class="checkbox mb-1-5">
              <label class="mb-0 d-inline-block cursor-pointer">
                <input
                  v-model="formFieldsCalculated[key].activated"
                  type="checkbox"
                  :checked="formFieldsCalculated[key].activated"
                  class="activateWorkingHours"
                />
                <i class="input-helper"></i>
                <div class="text-black h6 mb-0">
                  {{ weekdayList[key] }}
                </div>
              </label>
            </div>
            <div class="d-flex align-items-center mb-1-5">
              <p class="text-small text-black mb-0 poppins-font font-weight-medium text-nowrap mr-1-5">
                {{ $t('salon-working-hours.form-label.from') }}
              </p>
              <div class="input-group mr-0-5 position-relative small-input">
                <select
                  v-model="formFieldsCalculated[key].start_hours"
                  v-validate="formFieldsCalculated[key].activated ? selectValidateRule : ''"
                  :disabled="!formFieldsCalculated[key].activated"
                  :name="`start_hours_${key}`"
                  class="form-control w-100 px-0-5 centered-select"
                  style="font-size:14px;"
                >
                  <option
                    v-for="(option, key) in formFieldsCalculated[key].startHoursList"
                    :key="`start_hours_${key}`"
                    :value="option"
                  >
                    {{ option }}
                  </option>
                </select>
              </div>
              <div class="input-group mr-0-5 position-relative small-input">
                <select
                  v-model="formFieldsCalculated[key].start_minutes"
                  v-validate="formFieldsCalculated[key].activated ? selectValidateRule : ''"
                  :disabled="!formFieldsCalculated[key].activated"
                  :name="`start_minutes_${key}`"
                  class="form-control w-100 px-0-5 centered-select"
                  style="font-size:14px;"
                >
                  <option
                    v-for="(option, key) in formFieldsCalculated[key].startMinutetsList"
                    :key="`start_minutes_${key}`"
                    :value="option"
                  >
                    {{ option }}
                  </option>
                </select>
              </div>
              <p class="text-small text-black mb-0 ml-5 font-weight-medium text-nowrap mr-1-5">
                {{ $t('salon-working-hours.form-label.to') }}
              </p>
              <div class="input-group mr-0-5 position-relative small-input">
                <select
                  v-model="formFieldsCalculated[key].end_hours"
                  v-validate="formFieldsCalculated[key].activated ? selectValidateRule : ''"
                  :disabled="!formFieldsCalculated[key].activated"
                  :name="`end_hours_${key}`"
                  class="form-control w-100 px-0-5 centered-select"
                  style="font-size:14px;"
                >
                  <option
                    v-for="(option, key) in formFieldsCalculated[key].endHoursList"
                    :key="`end_hours_${key}`"
                    :value="option"
                  >
                    {{ option }}
                  </option>
                </select>
              </div>
              <div class="input-group mr-0-5 position-relative small-input">
                <select
                  v-model="formFieldsCalculated[key].end_minutes"
                  v-validate="formFieldsCalculated[key].activated ? selectValidateRule : ''"
                  :disabled="!formFieldsCalculated[key].activated"
                  :name="`end_minutes_${key}`"
                  class="form-control w-100 px-0-5 centered-select"
                  style="font-size:14px;"
                >
                  <option
                    v-for="(option, key) in formFieldsCalculated[key].endMinutetsList"
                    :key="`end_minutes_${key}`"
                    :value="option"
                  >
                    {{ option }}
                  </option>
                </select>
              </div>
            </div>

            <span
              v-show="
                errors.has(`end_minutes_${key}`) ||
                  errors.has(`end_hours_${key}`) ||
                  errors.has(`end_minutes_${key}`) ||
                  errors.has(`start_hours_${key}`) ||
                  errors.has(`start_minutes_${key}`)
              "
              class="error-message mb-1 col-12 pl-0"
            >
              {{ $t('salon-working-hours.errors.full-day-select') }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import PageNavigator from '@/components/PageNavigator'
import TimeConstants from '@/_constants/time.js'
import moment from 'moment'

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'SalonWorkingHours',
  components: {
    PageNavigator
  },
  data() {
    return {
      dataLoaded: false,
      submitProcess: false,
      hoursList: TimeConstants.HOURS_LIST,
      minutesList: TimeConstants.MINUTES_LIST,
      selectValidateRule: 'required',
      updateSuccessMessage: {
        type: 'Success!',
        messageI18Key: 'common.alerts.actions.updated'
      },
      updateErrorMessage: {
        type: 'Error!',
        messageI18Key: 'common.alerts.actions.global_error'
      },
      formFields: [
        {
          activated: false,
          start_hours: '',
          start_minutes: '',
          end_hours: '',
          end_minutes: ''
        },
        {
          activated: false,
          start_hours: '',
          start_minutes: '',
          end_hours: '',
          end_minutes: ''
        },
        {
          activated: false,
          start_hours: '',
          start_minutes: '',
          end_hours: '',
          end_minutes: ''
        },
        {
          activated: false,
          start_hours: '',
          start_minutes: '',
          end_hours: '',
          end_minutes: ''
        },
        {
          activated: false,
          start_hours: '',
          start_minutes: '',
          end_hours: '',
          end_minutes: ''
        },
        {
          activated: false,
          start_hours: '',
          start_minutes: '',
          end_hours: '',
          end_minutes: ''
        },
        {
          activated: false,
          start_hours: '',
          start_minutes: '',
          end_hours: '',
          end_minutes: ''
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      accountInfo: 'user/getAccountInfo',
      workDays: 'workDays/getWorkDays',
      siteLang: 'user/getSiteLang'
    }),
    weekdayList() {
      moment.updateLocale(this.siteLang, {
        week: {
          dow: 1
        }
      })

      return moment.localeData(this.siteLang).weekdays(true)
    },

    formFieldsCalculated() {
      return this.formFields.map(field => {
        field.startHoursList = this.hoursList

        let minMinuteStart = 0
        let maxMinuteEnd = 45
        let lastHourStart = field.startHoursList[field.startHoursList.length - 1]

        // Calculate end minutes list
        field.endHoursList = this.hoursList.filter((hour, index) => hour >= field.start_hours)

        let lastHourEnd = this.hoursList[this.hoursList.length - 1]
        let firstHourEnd = this.hoursList[0]

        // Calculate start minutes list
        field.startMinutetsList = this.calculateStartMinutes(
          field.start_hours,
          lastHourStart,
          lastHourEnd,
          firstHourEnd,
          minMinuteStart,
          maxMinuteEnd
        )

        // Calculate end minutes list
        field.endMinutetsList = this.calculateEndMinutes(
          field.end_hours,
          field.start_hours,
          lastHourEnd,
          field.start_minutes,
          maxMinuteEnd
        )

        // if (!field.startMinutetsList.includes(field.start_minutes)) field.start_minutes = ''
        if (!field.endMinutetsList.includes(field.end_minutes)) field.end_minutes = ''
        if (!field.startHoursList.includes(field.start_hours)) field.start_hours = ''
        if (!field.endHoursList.includes(field.end_hours)) field.end_hours = ''

        if (field.end_hours === '') {
          field.end_minutes = ''
          field.endMinutetsList = []
        }

        return field
      })
    }
  },
  mounted() {
    if (this.accountInfo.salon_id) {
      this.dataLoaded = false
      this.fetchWorkDays(this.accountInfo.salon_id).then(response => {
        this.workDays.forEach((day, key) => {
          this.formFields[key].id = day.id

          if (day.start_time) {
            this.formFields[key].activated = true
            this.formFields[key].start_hours = this.calcHours(day.start_time)
            this.formFields[key].start_minutes = this.calcMinutes(day.start_time)
          }

          if (day.end_time) {
            this.formFields[key].activated = true
            this.formFields[key].end_hours = this.calcHours(day.end_time)
            this.formFields[key].end_minutes = this.calcMinutes(day.end_time)
          }
        })

        this.dataLoaded = true
      })
    }
  },
  methods: {
    ...mapActions({
      fetchWorkDays: 'workDays/getWorkDays',
      updateAll: 'workDays/updateAll',
      setSuccess: 'userMessages/setSuccess',
      setError: 'userMessages/setError'
    }),
    submitForm() {
      this.submitProcess = true

      this.$validator.validateAll().then(result => {
        if (result) {
          const daysPlayloadData = []

          this.formFields.forEach(fields => {
            let working_hours = {
              id: null,
              start_time: null,
              end_time: null
            }

            working_hours.id = fields.id

            if (!fields.activated) {
              daysPlayloadData.push(working_hours)
            } else {
              working_hours.start_time = this.calcFullTime(fields.start_hours, fields.start_minutes)
              working_hours.end_time = this.calcFullTime(fields.end_hours, fields.end_minutes)
              daysPlayloadData.push(working_hours)
            }
          })

          const payload = {
            ...{ salon_id: this.accountInfo.salon_id },
            ...{ working_hours: daysPlayloadData }
          }

          this.updateAll(payload)
            .then(response => {
              this.submitProcess = false
              this.setSuccess(this.updateSuccessMessage)
              this.$router.push('/salon')
            })
            .catch(error => {
              this.submitProcess = false
              this.setError(this.updateErrorMessage)
            })
        } else {
          this.submitProcess = false
        }
      })
    },
    calcFullTime(hours, minutes) {
      return `${hours}:${minutes}`
    },
    calcHours(time) {
      return Number(time.split(':')[0])
    },
    calcMinutes(time) {
      let minutes = Number(time.split(':')[1])

      if (minutes === 0) {
        minutes = '00'
      }

      return minutes
    },
    calculateStartMinutes(startHour, lastHourStart, lastHourEnd, firstHourEnd, minMinuteStart, maxMinuteEnd) {
      let minutes = []

      if (startHour === lastHourStart && startHour === lastHourEnd) {
        minutes = this.minutesList.filter(minute => Number(minute) <= maxMinuteEnd)
      } else if (startHour === firstHourEnd && startHour) {
        minutes = this.minutesList.filter(minute => Number(minute) >= minMinuteStart)
      } else if (startHour === lastHourEnd && startHour) {
        minutes = this.minutesList.filter(minute => Number(minute) <= maxMinuteEnd)
      } else {
        minutes = this.minutesList
      }

      return minutes
    },
    calculateEndMinutes(endHour, startHour, lastHourEnd, startMinute, maxMinuteEnd) {
      let minutes = []

      if (endHour === lastHourEnd) {
        minutes = this.minutesList.filter(minute => {
          if (startHour === lastHourEnd) {
            return Number(minute) >= startMinute && Number(minute) <= maxMinuteEnd
          } else {
            return Number(minute) <= maxMinuteEnd
          }
        })
      } else if (endHour === startHour) {
        minutes = this.minutesList.filter(minute => Number(minute) >= startMinute)
      } else {
        minutes = this.minutesList
      }

      return minutes
    }
  }
}
</script>

<style lang="scss" scoped></style>
